import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Chip, Container, Grid, Pagination,
} from '@mui/material';
import {
  TbBus, TbClock, TbGrill, TbPower, TbWallet,
} from 'react-icons/tb';
import Moment from 'moment';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../Components/PageHeader';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import IntroCeeAPI from '../../APIs/IntroCeeAPI';

function Account() {
  const { user, logout } = useContext(UserContext);
  const api = IntroCeeAPI();
  const [barActivity, setBarActivity] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.name) {
      api.getActivity().then((res) => {
        setBarActivity(res.data);
      });
    }
  }, [user]);

  const navigateToTopup = () => {
    if (!user.is_signed_up_barbecue && !user.is_signed_up_Weekend) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You are not signed up for the Introduction Weekend or Barbecue. Please sign up to one of the activities first.',
      });
      return;
    }
    navigate('/topup');
  };

  return user && user.name && (
    <div className="account">
      <PageHeader title={`Account (${user.name})`} />
      <Container maxWidth="lg">
        <div className="section-heading">
          <h2>Account Details</h2>
          <Button
            className="btn btn-red"
            startIcon={<TbPower />}
            onClick={logout}
          >
            Log Out
          </Button>
        </div>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} lg={3}>
            <div className="box">
              <TbBus className="box-icon" />
              <h3>Introduction Weekend</h3>
              {user.is_signed_up_weekend ? (
                <p>
                  You are signed up for the Introduction Weekend!
                  {' '}
                  <b>Keep an eye</b>
                  {' '}
                  on your email for more information.
                </p>
              ) : (
                <>
                  {user.signup_weekend && user.signup_weekend.status === 'PENDING_APPROVAL' && (
                    <p>
                      Your signup for the Introduction Weekend is
                      {' '}
                      <b>pending approval</b>
                      {' '}
                      from the Introduction Committe. If your signup is approved,
                      you will receive an email with more information.
                    </p>
                  )}
                  {user.signup_weekend && user.signup_weekend.status === 'APPROVED' && (
                    <>
                      <p>
                        Your weekend signup has been
                        {' '}
                        <b>approved</b>
                        {' '}
                        and is awaiting your payment. Do it as soon as
                        possible to secure your spot!
                      </p>
                      <Button
                        className="btn btn-purple"
                        href="/signup?weekend=true"
                        sx={{ mt: 3 }}
                      >
                        Complete Payment
                      </Button>
                    </>
                  )}
                  {(!user.signup_weekend
                    || (user.signup_weekend.status !== 'APPROVED'
                      && user.signup_weekend.status !== 'PENDING_APPROVAL')) && (
                      <>
                        <p>
                          You are not signed up for the Introduction Weekend.
                          {' '}
                          <b>Make sure to sign up while there are still spots left!</b>
                        </p>
                        <Button
                          className="btn btn-purple"
                          href="/signup"
                          sx={{ mt: 3 }}
                        >
                          Sign Up
                        </Button>
                      </>
                  )}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} lg={3}>
            <div className="box">
              <TbGrill className="box-icon" />
              <h3>Introduction Barbecue</h3>
              {user.is_signed_up_barbecue ? (
                <p>
                  You are signed up for the barbecue!
                  {' '}
                  <b>Keep an eye</b>
                  {' '}
                  on your email for more information.
                </p>
              ) : (
                <>
                  <p>
                    You are not signed up for the Introduction Barbecue.
                    {' '}
                    <b>Make sure to sign up while there are still spots left!</b>
                  </p>
                  <Button
                    className="btn btn-purple"
                    href="/signup"
                    sx={{ mt: 3 }}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className="box">
              <h3>Wallet</h3>
              <span className="wallet-balance">
                <TbWallet />
                €
                {' '}
                {user.balance.toFixed(2)}
              </span>
              <small>
                During the weekend & barbecue you will only be able to pay at the bar
                using your wallet, so make sure to top up in advance. At the end of the
                introduction period, we will reimburse all funds left over in your wallet.
                You can avoid to pay multiple commissions if you top up more in the beginning.
              </small>
              <Button
                className="btn btn-vanilla"
                onClick={navigateToTopup}
                sx={{ mt: 3, width: '100%' }}
              >
                Top Up
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            {barActivity && (
              <div className="box">
                <h3>Bar Activity</h3>
                {barActivity.slice((page - 1) * 10, page * 10).map((activity) => (
                  <div className="bar-activity" key={activity.id}>
                    <span className="details">
                      {'amount' in activity ? 'Top Up' : `${activity.quantity}x ${activity.product.name}`}
                      <Chip
                        label={(
                          <>
                            <TbClock />
                            {' '}
                            {Moment(activity.created_at).format('YYYY-MM-DD HH:mm')}
                          </>
                        )}
                        size="small"
                      />
                    </span>
                    <span className="amount">
                      €
                      {' '}
                      {'amount' in activity ? `${activity.amount}` : `${activity.total_price}`}
                    </span>
                  </div>
                ))}
                <Pagination
                  count={barActivity.length / 10}
                  page={page}
                  onChange={(event, value) => setPage(value)}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className="box">
              <h3>QR Code</h3>
              <p>
                Whenever we need to find you in the database of participants (e.g. for checking in),
                it is easiest to scan your QR code. You can find your QR code below. We will also
                email this QR code to you, but it is easiest if you save the image.
              </p>
              <img
                className="qr"
                src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${user.id}`}
                alt="QR Code"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Account;
